<template>
  <div v-if="displayDetails && island">
    <b-button @click="displayDetails = false;" class="mb-2">Back</b-button>
    <map-card-detail :island="island" />
    Player Count History
    <snapshots-chart :snapshots="snapshots" :maps="maps" countKey="pc" />
    <b-button @click="checkDetails(true)" class="mt-2">Force Update</b-button>
  </div>
  <b-card v-else-if="map">
    <img :src="map.image" class="card-img-top" alt="Map Image">
    <b-card-title>{{ map.name }}</b-card-title>
    <b-card-text>
      Creator: {{ map.author }}<br>
      Island Code: {{ map.islandCode }}<br>
      Players: {{ map.playerCount }}
      <div class="sub-mt-2 sub-mr-2">
        <b-badge v-for="(tag, index) in map.tags" :key="index" variant="primary">{{ tag }}</b-badge>
      </div>
    </b-card-text>
    <b-button @click="addBookmark(map)">
      <font-awesome-icon icon="bookmark" />
    </b-button>
    <b-button class="ml-2" @click="displayDetails = true;" v-if="island">
      <font-awesome-icon icon="info-circle" />
    </b-button>
    <b-button class="ml-2" @click="checkDetails(true).then(() => displayDetails = true)" v-else-if="details">
      <font-awesome-icon icon="search-plus" />
    </b-button>
    <b-button class="ml-2" @click="$emit('show-details', map)" v-else>
      <font-awesome-icon icon="eye" />
    </b-button>
    <b-button class="ml-2" @click="openMap(map)">
      <font-awesome-icon icon="external-link-alt" />
    </b-button>
  </b-card>
</template>

<script>
import { db } from '@/services/db'
import { getLog } from '@/services/log'
import { checkIslandStats } from '@/services/functions'
const log = getLog('map-card')

export default {
  components: {
    MapCardDetail: () => import('./MapCardDetail.vue'),
    SnapshotsChart: () => import('./SnapshotsChart.vue'),
  },
  props: {
    map: {
      type: Object,
      required: true
    },
    details: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      island: null,
      playerCountHistory: null,
      displayDetails: false,
      displayViewButton: false,
      snapshots: [],
      maps: [],
    }
  },
  // Watching playerCountHistory to update snapshots
  watch: {
    playerCountHistory: {
      handler: function (val) {
        log.log("playerCountHistory", val, "map", this.map);
        if (val) {
          this.convertChart(val, this.map);
        }
        else
          this.snapshots = [];
      },
    }
  },
  mounted() {
    if (this.details)
      this.checkDetails();
  },
  methods: {
    async addBookmark(map) {
      try {
        const user = this.$store.account;
        if (!user) {
          throw new Error('User not authenticated')
        }
        const bookmarkRef = db.collection(`ScrapFNUsers/${user.uid}/bookmarks`).doc(map.islandCode)
        await bookmarkRef.set(map)
        log.log(`Added bookmark for map ${map.name}`)
      } catch (error) {
        log.error(`Error adding bookmark for map ${map.name}: ${error.message}`)
      }
    },
    async checkDetails(forceUpdate = false) {
      if (!this.$firestoreRefs.playerCountHistory) 
        this.$bind("playerCountHistory", db.collection("/ScrapFNMapsHistoryAll/").doc(this.map.islandCode));
      if (!this.$firestoreRefs.island)
        this.$bind("island", db.collection("/ScrapFNMapsInfo/").doc(this.map.islandCode));
      if (forceUpdate) {
        await checkIslandStats({ islandCode: this.map.islandCode, forceUpdate });
      }
    },
    openMap(map) {
      // https://fchq.io/map/
      // https://www.fortnite.com/creative/island-codes/
      window.open(`https://www.fortnite.com/creative/island-codes/${map.islandCode}`, '_blank')
    },
    // convert playerCountHistory into a chart
    convertChart(playerCountHistory, map) {
      log.log("convertChart");//, playerCountHistory, map);
      let snapshots = [];
      for (const [time, pc] of Object.entries(playerCountHistory)) {
        let timestamp = { toDate: () => new Date(parseInt(time)) };
        let m = {
          name: map.name,
          islandCode: map.islandCode,
          pc,
        };
        snapshots.push({
          timestamp,
          maps: [m],
        });
      }
      snapshots.push({
        timestamp: { toDate: () => new Date() },
        maps: [{
          name: map.name,
          islandCode: map.islandCode,
          pc: 0,
        }],
      });
      this.maps = [{
        name: map.name,
        islandCode: map.islandCode,
      }];
      this.snapshots = snapshots;
    },
  }
}
</script>